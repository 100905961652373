import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { 
    // formatAmount, 
    amountToWords } from "../../../../utils";

import grn_banner from '../../../../assets/grn_banner.jpg';
import femi from '../../../../assets/femi_signature.png';
import livinus from '../../../../assets/livinus_signature.png';

const styles = StyleSheet.create({
    relative:{
        position:'relative',
        top: 0,
    },
    section: {
        margin: 10,
        marginBottom: 5,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    footer: {
        marginTop: 20,
        fontSize: 11,
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.2,
        position:"absolute",
        bottom: 15,
        left: 20,
    },
    rightSection: {
        textAlign: "right",
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.7,
        display:"block",
        width: "100%",
    },
    textLeft: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 0,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    footerTextRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textRight: {
        textAlign: "right",
        fontSize: 9,
    },
    textBold: {
        fontWeight: "bold",
    },
    table: {
        padding: 0,
        margin: 0,
        fontSize: 10,
    },
    row: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        borderLeft: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    tablehead: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        backgroundColor: '#000',
        color:'#fff',
        padding: 0,
        margin: 0,
    },
    bodyrow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        // borderLeft: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    tablerow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        margin: 0,
        height: 24,
    },
    th: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#aaa",
        borderLeft: '1px solid #000',
        color: "#000"
    },
    thtransparent: {
        fontWeight: "bold",
        fontSize: 10,
        padding: 4,
        paddingLeft: 0,
        margin: 0,
        color: "#000"
    },
    thd: {
        overflow: 'hidden',
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        borderLeft: "1px solid #000",
        borderBottom: "1px solid #000",
        borderTopWidth: 0,
    },
    thtrans: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        borderLeft: '1px solid #000',
    },
    foot: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',        
        padding: 0,
        margin: 0,
    },
    td: {
        overflow: 'hidden',
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        borderLeft: "1px solid #000",
        borderBottom: "1px solid #000",
        borderTopWidth: 0,
    },
    desc10: {
        width: "10%",
    },
    desc15: {
        width: "15%",
        textAlign: "right",
    },
    desc5: {
        width: "5%",
        textAlign: "left",
    },
    desc50: {
        width: "50%",
    },
    desc65: {
        width: "65%",
        textAlign: "left",
    },
    desc50left: {
        width: "50%",
        textAlign:"left",
    },
});

const TableHead = () => {
    return (
        <>
            <View style={[styles.tablehead]}>
                <Text style={[styles.th, styles.desc5]}>SN</Text>
                <Text style={[styles.th, styles.desc50]}>Item Description</Text>
                <Text style={[styles.th, styles.desc10]}>Quantity Ordered</Text>
                <Text style={[styles.th, styles.desc10]}>Quantity Supplied</Text>
                <Text style={[styles.th, styles.desc10]}>Unit Cost</Text>
                <Text style={[styles.th, styles.desc15, {borderRight: '1px solid #000'}]}>Total</Text>
            </View>
        </>
    )
}

// const TableFoot = ({ totalCost }) => {
//     console.log(totalCost)

//     return (
//         <>
//             <View style={[styles.foot, {borderBottom: '1px solid #000', borderTop:'none'}]}>
//                 <Text style={[styles.thtrans, styles.desc65]}>&nbsp;</Text>
//                 <Text style={[styles.thd, styles.desc10]}>Total Amount:</Text>
//                 <Text style={[styles.thd, styles.desc15, {textAlign:'right'}]}>
//                     {totalCost ? formatAmount(totalCost, "en-NG") : formatAmount(totalCost, "en-NG")}
//                 </Text>
//             </View>
//         </>
//     )
// }

export const DocGRN = ({ grn }) => {

    console.log(grn)

    let totalCost = grn?.items.map((item) => item.total).reduce((prev, curr) => prev + curr, 0);

    const ReceivingPartOne = () => {
        return (
            <>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc50left]}>Inventory Owner:</Text>
                    <Text style={[styles.thtransparent, styles.desc50left]}>{grn && grn.division?.name}</Text>
                </View>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc50left]}>Date:</Text>
                    <Text style={[styles.thtransparent, styles.desc50left]}>{grn && (grn.submittedAt).slice(0, -12)}</Text>
                </View>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc50left]}>Vendor Name:</Text>
                    <Text style={[styles.thtransparent, styles.desc50left]}>{grn && (grn.vendor?.companyName || grn?.vendorName)}</Text>
                </View>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc50left]}>Purchase Order No:</Text>
                    <Text style={[styles.thtransparent, styles.desc50left]}>{grn && grn.purchaseOrderNo}</Text>
                </View>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc50left]}>GRN No:</Text>
                    <Text style={[styles.thtransparent, styles.desc50left]}>{grn && grn.documentNo}</Text>
                </View>
            </>
        )
    }

    return (
        <>
            <View style={{position: "relative"}}>
                <Image
                    src={grn_banner}
                    style={{
                        height: 130,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 20,
                        paddingLeft: 20,
                        marginBottom: 10,
                    }} 
                />

                {/* Page Content */}
                <View>
                    <View style={styles.section}>
                        <View style={{width: '100%', textAlign:'left', fontSize: 13, fontWeight: 'bold' }}>
                            <Text style={{width: '100%', textAlign:'left', fontSize: 13, fontWeight: 'bold' }}>
                                <strong>RS-FIN-ACC-ICC-P-10603-7 GOODS RECEIVED NOTE FORM</strong>
                            </Text>
                        </View>
                    </View>
                    
                    <View style={styles.section}>
                        <ReceivingPartOne />
                    </View>
                    
                    {/* Items */}
                    {grn && grn.items && grn.items.length > 0 ? (
                        <View style={styles.section}>
                            <View style={styles.table}>
                                
                                <TableHead />

                                {(grn.items).map((item, i) => (
                                    <View key={i}>
                                        <View  style={[styles.bodyrow]}>
                                            <Text style={[styles.td, styles.desc5]}>
                                                {i + 1}
                                            </Text>
                                            <Text style={[styles.td, styles.desc50]}>
                                                {item.description && item.description}
                                            </Text>
                                            <Text style={[styles.td, styles.desc10]}>
                                                {item.quantityOrdered && item.quantityOrdered}
                                            </Text>
                                            <Text style={[styles.td, styles.desc10]}>
                                                {item.quantityDelivered && item.quantityDelivered}
                                            </Text>
                                            <Text style={[styles.td, styles.desc10]}>
                                                {item.cost && item.cost}
                                            </Text>
                                            <Text style={[styles.td, styles.desc15, {borderRight: '1px solid #000'}]}>
                                                {item.total && item.total}
                                            </Text>
                                        </View>
                                    </View>
                                ))}

                                {/* <TableFoot totalCost={totalCost ? totalCost : 0 } /> */}

                            </View>
                        </View>
                    ) : null}


                    <View style={[styles.section, {fontSize: 12}]}>
                        
                        {totalCost && (totalCost !== "" || totalCost !== 0) ? 
                        <Text style={[styles.textLeft, styles.textBold, {fontSize: 12, marginTop: 10}]}><strong>Amount In Words:</strong> {" "}
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {totalCost && totalCost ? amountToWords(totalCost || 0) : "N/A"} 
                                {totalCost && totalCost ? " Naira" : null}
                            </Text>
                        </Text> : null}


                        <Text style={[styles.textLeft, styles.textBold, {fontSize: 12, marginTop: 10}]}><strong>Requester:</strong> {" "}
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {grn && grn.division ? grn.division?.name : null}
                            </Text>
                        </Text>

                        {/* prepared by */}
                        <Text style={[styles.textLeft, styles.textBold, {fontSize: 12, marginTop: 10}]}><strong>Prepared By:</strong> {" "}
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {grn && grn.submittedBy ? grn.submittedBy?.userName : null}
                            </Text> 
                            {"  "} 
                            <Text style={[styles.text, styles.textRight, {fontSize: 12}]}>
                                {grn && grn.submittedAt ? (grn.submittedAt).slice(0, -12) : null}
                            </Text>
                        </Text>

                        {/* asset manager approval by */}
                        <Text style={[styles.textLeft, styles.textBold, {fontSize: 12, marginTop: 10}]}><strong>Asset Manager Approval By: </strong> {" "}
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {grn && grn.approvedBy ? grn.approvedBy?.userName : null}
                            </Text> 
                            {"  "} 
                            <View style={[styles.text]}>
                                {(grn && grn.approvedBy && grn?.approvedBy?.userName === "Anerua Henry Idaewor") ? 
                                <Image 
                                    src={femi} 
                                /> : 
                                <Image 
                                    src={livinus} 
                                /> 
                                }
                            </View> 
                            {"  "} 
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {grn && grn.approvedAt ? (grn.approvedAt).slice(0, -12) : null}
                            </Text>
                        </Text>

                        {/* finance manager approval by */}
                        <Text style={[styles.textLeft, styles.textBold, {fontSize: 12, marginTop: 10}]}><strong>Finance Manager Approval By: </strong> {" "}
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {grn && grn.acceptedBy ? grn.acceptedBy?.userName : null}
                            </Text> 
                            {"  "} 
                            <View style={[styles.text]}>
                                {(grn && grn.acceptedBy && grn?.acceptedBy?.userName === "Divakar Puri") ? 
                                <Image 
                                    src={femi} 
                                /> : 
                                <Image 
                                    src={livinus} 
                                /> 
                                }
                            </View> 
                            {"  "} 
                            <Text style={[styles.text, {fontSize: 12}]}>
                                {grn && grn.acceptedAt ? (grn.acceptedAt).slice(0, -12) : null}
                            </Text>
                        </Text>
                    </View>
                </View>
            </View>
        </>
    )
}

export default DocGRN;
