import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import procurement from '../../../../assets/procurement.jpeg';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 30,
        textAlign: "justify",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 8.9,
        textAlign: "justify",
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: "bold",
        color: "#222",
        lineHeight: 2.0,
        marginBottom: 5,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    textRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
});

export const PageFive = () => {
    return (
        <>
            <View break>
                <Image
                    src={procurement}
                    style={{
                        height: 120,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 30,
                        paddingLeft: 30,
                        marginBottom: 0,
                    }} 
                />
                <View style={styles.section}>   
                    <Text style={styles.text}>
                        now or becomes publicly known through no wrongful act or failure to act of
                        Seller,  (iii) which Seller developed independently without use of the COMPANY Confidential Information, as evidenced by appropriate documentation, or (iv)
                        which is hereafter lawfully furnished to Seller by a third party as a matter of right and without restriction on disclosure. In addition, Seller may disclose
                        Confidential Information which is required to be disclosed pursuant to a requirement of a government agency or law so long as Seller provides prompt notice
                        to COMPANY of such requirement prior to disclosure. Seller agrees not to copy, alter, or directly or indirectly disclose any COMPANY Confidential Information.
                        Additionally, Seller agrees to limit its internal distribution of COMPANY Confidential Information to Seller's Assistants who have a need to know, and to take
                        steps to ensure that the dissemination is so limited, including the execution by Seller's Assistants of nondisclosure agreements with provisions substantially
                        similar to those set forth herein. In no event will Seller use less than the degree of care and means that it uses to protect its own information of like kind, but in
                        any event not less than reasonable care to prevent the unauthorized use of COMPANY Confidential Information Seller further agrees not to use the
                        COMPANY Confidential Information except in the course of performing hereunder and will not use such COMPANY Confidential Information for its own benefit
                        or for the benefit of any third party. The mingling of the COMPANY Confidential Information with information of Seller shall not affect the confidential nature or
                        ownership of the same as stated hereunder. Seller agrees not to design or manufacture any products which incorporate COMPANY Confidential Information.
                        All COMPANY Confidential Information is and shall remain the property of COMPANY. Upon COMPANY's written request or the termination of this Agreement,
                        Seller shall return, transfer, or assign to COMPANY all COMPANY Confidential Information, including all Work Product, as defined herein, and all copies
                        thereof.
                    </Text> 
                        
                    <Text style={styles.boldText}>12. OWNERSHIP OF WORK PRODUCT.</Text>
                    <Text style={styles.text}>
                        progress, Service deliverables, inventions, products, computer programs, procedures, improvements, developments, drawings, notes, documents, information
                        and materials made, conceived, or developed by Seller, alone or with others, which result from or relate to the Services performed hereunder. Standard Goods
                        manufactured by Seller and sold to COMPANY without having been designed, customized, or modified for COMPANY do not constitute Work Product. All
                        Work Product shall at all times be and remain the sole and exclusive property of COMPANY. Seller hereby agrees to irrevocably assign and transfer to
                        COMPANY and does hereby assign and transfer to COMPANY all of its worldwide right, title, and interest in and to the Work Product including all associated
                        intellectual property rights. COMPANY will have the sole right to determine the treatment of any Work Product, including the right to keep it as trade secret,
                        execute and file patent applications on it, to use and disclose it without prior patent application, to file registrations for copyright or trademark in its own name,
                        or to follow any other procedure that COMPANY deems appropriate. Seller agrees: (a) to disclose promptly in writing to COMPANY all Work Product in its
                        possession; (b) to assist COMPANY in every reasonable way, at COMPANY's expense, to secure, perfect, register, apply for, maintain, and defend for
                        COMPANY's benefit all copyrights, patent rights, mask work rights, trade secret rights, and all other proprietary rights or statutory protections in and to the
                        Work Product in COMPANY's name as it deems appropriate; and (c) to otherwise treat all Work Product as COMPANY Confidential Information as described
                        above. These obligations to disclose, assist, execute, and keep confidential survive the expiration or termination of this Agreement. All tools and equipment
                        supplied by COMPANY to Seller shall remain the sole property of COMPANY. Seller will ensure that Seller's Assistants appropriately waive any and all claims
                        and assign to COMPANY any and all rights or any interests in any Work Product or original works created in connection with this Agreement. Seller irrevocably
                        agrees not to assert against COMPANY or its direct or indirect customers, assignees, or licensees any claim of any intellectual property rights of Seller
                        affecting the Work Product. COMPANY will not have rights to any works conceived or reduced to practice by Seller which were developed entirely on Seller's
                        own time without using equipment, supplies, facilities, or trade secret or COMPANY Confidential Information, unless (i) such works relate to COMPANY's
                        business, or COMPANY's actual or demonstrably anticipated research or development, or (ii) such works result from any Services performed by Seller for COMPANY.
                    </Text>

                    <Text style={styles.boldText}>13. NON-INTERFERENCE WITH BUSINESS.</Text>
                    <Text style={styles.text}>
                        During and for a period of two years immediately after the termination or expiration of this Agreement, Seller agrees not to unlawfully 
                    </Text>

                    <Text style={[styles.textUnderline]}>
                        {"________________________________________________________________________________"}
                    </Text>
                    <Text style={[styles.footerTextLeft]}>RS-CPD-PMG-PUR-P-10106 Procurement</Text>
                    <Text style={[styles.textRight]}>Unauthorized Printing Is Not Permitted</Text>

                </View>
            </View>
        </>
    )
}

export default PageFive;
