import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import procurement from '../../../../assets/procurement.jpeg';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 30,
        textAlign: "justify",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 8.9,
        textAlign: "justify",
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: "bold",
        color: "#222",
        lineHeight: 2.0,
        marginBottom: 5,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    textRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
});

export const PageSeven = ({ quote }) => {
    return (
        <>
            <View break>
                <Image
                    src={procurement}
                    style={{
                        height: 120,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 30,
                        paddingLeft: 30,
                        marginBottom: 0,
                    }} 
                />
                <View style={styles.section}>
                    <Text style={styles.text}>INCIDENTAL, INDIRECT,
                        SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF, OR IN CONNECTION WITH, THIS AGREEMENT, WHETHER OR NOT COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, AND WHETHER OR NOT THERE IS A FAILURE OF ANY AGREED REMEDY.
                    </Text>

                    <Text style={styles.boldText}>20. ASSIGNMENT; WAIVER.</Text>
                    <Text style={styles.text}>
                        Seller may not assign this Agreement or any of its rights or obligations under this Agreement, without the prior written consent of COMPANY. Any assignment
                        or transfer without such written consent shall be null and void. A waiver of any default hereunder or of any term or condition of this Agreement shall not be
                        deemed to be a continuing waiver or a waiver of any other default or any other term or condition
                    </Text>

                    <Text style={styles.boldText}>21. NONEXCLUSIVE AGREEMENT.</Text>

                    <Text style={styles.text}>
                        This is not an exclusive agreement. COMPANY is free to engage others to perform Services or provide Goods the same as or similar to Seller's. Seller is free
                        to, and is encouraged to, advertise, offer, and provide Seller's Services and/or Goods to others; provided however, that Seller does not breach this Agreement. 
                    </Text>    
                        
                    <Text style={styles.boldText}>22. NOTICES.</Text>
                    
                    <Text style={styles.text}>
                        Except for Purchase Orders which may be sent by local mail, facsimile transmission, or electronically transmitted,, all notices, and other communications
                        hereunder shall be in writing, nd shall be addressed to Seller or to an authorized COMPANY representative, and shall be considered given when (a) delivered
                        personally, (b) sent by confirmed telex or facsimile, (c) sent by commercial overnight courier with written verification receipt, or (d) three (3) days after having
                        been sent, postage prepaid, by first class or certified mail.
                    </Text>

                    <Text style={styles.boldText}>23. SURVIVAL OF OBLIGATIONS.</Text>
                    <Text style={styles.text}>
                        Any obligations and duties which by their nature extend beyond the expiration or termination of this Agreement shall survive the expiration or termination of
                        this Agreement.
                    </Text>

                    <Text style={styles.boldText}>24. GOVERNING LAW.</Text>
                    <Text style={styles.text}>
                        This Agreement shall be construed in accordance with, and disputes shall be governed by, the laws of the Federal Republic of Nigeria excluding its conflict of  law rules. Jurisdiction and venue over all controversies arising out of, or relating to, this Agreement shall be in Nigeria. The applicability of the UN Convention
                        on Contracts for the International Sale of Goods is hereby expressly waived by the parties and it shall not apply to the terms and conditions of this Agreement.
                    </Text>
                    
                    <Text style={styles.boldText}>25. ENTIRE AGREEMENT; MODIFICATION.</Text>
                    <Text style={styles.text}>

                        This Agreement is the complete, final, and exclusive statement of the terms of the agreement between the parties and supersedes any and all other prior and
                        contemporaneous negotiations and agreements, whether oral or written, between them relating to the subject matter hereof. This Agreement may not be
                        varied, modified, altered, or amended except in writing, including a purchase order or a change order issued by COMPANY, signed by the parties. The terms
                        and conditions of this Agreement shall prevail notwithstanding any variance with the terms and conditions of any acknowledgment or other document
                        submitted by Seller. Notwithstanding the foregoing, this Agreement will not supersede or take the place of any written agreement which is signed by both
                        parties and covers the same subject matter as this Agreement or its related purchase orders.
                    </Text>

                    <Text style={styles.boldText}>26. COMPLIANCE WITH LAWS.</Text>
                    
                    <Text style={[styles.textUnderline]}>
                        {"________________________________________________________________________________"}
                    </Text>
                    <Text style={[styles.footerTextLeft]}>RS-CPD-PMG-PUR-P-10106 Procurement</Text>
                    <Text style={[styles.textRight]}>Unauthorized Printing Is Not Permitted</Text>

                </View>
            </View>
        </>
    )
}

export default PageSeven;
