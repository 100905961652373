import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import procurement from '../../../../assets/procurement.jpeg';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 30,
        textAlign: "justify",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 8.9,
        textAlign: "justify",
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: "bold",
        color: "#222",
        lineHeight: 2.0,
        marginBottom: 5,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    textRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
});

export const PageEight = ({ quote }) => {
    return (
        <>
            <View break style={{position:"relative"}}>
                <Image
                    src={procurement}
                    style={{
                        height: 120,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 30,
                        paddingLeft: 30,
                        marginBottom: 0,
                    }} 
                />
                <View style={styles.section}>
                    <Text style={styles.text}>26.1 General:</Text>

                    <Text style={styles.text}>
                        Seller shall comply fully with all applicable federal, state, and local laws in the performance of this Agreement including, but not limited to, all applicable
                        employment, tax, export control, and environmental laws.
                    </Text>

                    <Text style={styles.text}>26.3 Hazardous Materials:</Text>
                    <Text style={styles.text}>
                        If Goods include hazardous materials, Seller represents and warrants that Seller understands the nature of any hazards associated with the manufacture,
                        handling, and transportation of such hazardous materials
                    </Text>    
                    
                    <Text style={styles.text}>26.4 Customs:</Text>
                    <Text style={styles.text}>
                        Upon COMPANY's request, Seller will promptly provide COMPANY with a statement of origin for all Nigerian Customs documentation for Goods wholly or
                        partially manufactured outside of the Nigeria.
                    </Text>

                    <Text style={styles.boldText}>27. INJUNCTIVE RELIEF.</Text>
                    <Text style={styles.text}>
                        Seller acknowledges and agrees that the obligations and promises of Seller under this Agreement are of a unique, intellectual nature giving them particular
                        value. Seller's breach of any of the promises contained in this Agreement will result in irreparable and continuing damage to COMPANY for which there will be
                        no adequate remedy at law and, in the event of such breach, COMPANY will be entitled to seek injunctive relief, or a decree of specific performance.
                    </Text>
                    
                </View>
                
            </View>
        </>
    )
}

export default PageEight;
