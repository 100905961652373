import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import logo from '../../../../../assets/bgimage_.png';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 25,
    },
    caps: {
        fontWeight: 800,
        fontSize: 14,
        textTransform: "uppercase",
    },
    title: {
        marginBottom: 15,
    },
    title2: {
        marginBottom: 50,
    },
    textCenter: {
        textAlign: "center",
        fontSize: 12,
        lineHeight: 1.5
    },
    top_bg_img: {
        height: 40,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
});

export const PageOne = ({ quote }) => {

    return (
        <>
            <View>
                <Image
                    src={logo}
                    style={{
                        height: 80,
                        width: "100%",
                        margin: 0,
                        paddingRight: 40,
                        paddingLeft: 40,
                        marginBottom: 20,
                    }} />
                <View style={styles.section}>
                    <Text style={styles.title}>{quote && quote.quotationDate ?
                        moment(quote.quotationDate).format("MMMM Do YYYY") : null}</Text>
                    <Text style={styles.title2}>{quote && quote.customer ? quote.customer?.name : null}</Text>
                    <Text style={styles.title}>OUR REF: {quote && quote.documentNo ? quote.documentNo : null}</Text>
                    <Text style={styles.text2}>Attn: {quote && quote.contactPerson ? quote.contactPerson?.name : null}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>Dear Sir,</Text>
                    <Text style={styles.caps}>RE: {quote && quote.title ? quote.title : null}</Text>
                </View>
                {quote && quote.sendComment ? (
                    <View style={styles.section}>
                        <Text style={styles.text}>{quote && quote.sendComment ? quote.sendComment : "[[<u>The body of the mail will be populated by your comment when sending the quotation</u>]]"}</Text>

                    </View>
                ) : null}
            </View>
        </>
    )
}

export default PageOne;
