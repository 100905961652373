import React from "react";
import {
  Typography,
  makeStyles,
  Paper,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
} from "@material-ui/core";

import { connect } from "react-redux";
import moment from "moment";
import Feedback from "../../Feedback";
import WelcomeFeedback from "../Admin/WelcomeFeedback";
import russelsmithgrp from '../../assets/russelsmithgrp_logo.jpg';
import avatar from "../../assets/profile_av.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Quicksand",
    "& ::parent": {
      padding: 0,
    },
  },
  analytics: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 18,
    // color: darken(theme.palette.common.white, 0.3),
    letterSpacing: 2.5,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    maxWidth: 600,
    "&:after": {
      content: "''",
      position: "absolute",
      width: 70,
      borderRadius: 5,
      background: "#ccc",
      height: 2,
      flex: 1,
      display: "block",
      top: "45%",
      left: "102%",
      [theme.breakpoints.down("sm")]: {
        width: 50,
      },
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: 5,
      borderRadius: 5,
      background: "#ccc",
      height: 2,

      flex: 1,
      display: "block",
      top: "45%",
      right: "102%",
      [theme.breakpoints.down("sm")]: {
        width: 3,
      },
    },
  },
  date: {
    // marginLeft: 10,
    padding: 10,
    // background: "#07a4ace3",
    // color: "#fff",
    fontWeight: 900,
    fontSize: 12,
  },
  gridContainer2: {
    // padding: theme.spacing(4),
    marginTop: 30,
  },
  amount: {
    color: "#6F6767",
    fontWeight: 300,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  card: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(4),
  },
  time: {
    marginLeft: 5,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 900,
    fontSize: 12,
    maxWidth: 150,
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
    // color: darken(theme.palette.common.white, 0.3),
    letterSpacing: 2.5,
  },
  pos: {
    marginBottom: 12,
  },
  grid: {
    marginTop: 30,
  },
  helloText: {
    color: "#bdbdbd",
    fontFamily: "Rubik",
    letterSpacing: 1.5,
    fontSize: "1.55rem",
  },
  
  cardContent: {
    overflow: "auto",
    padding: 16,
  },
  
  cardContentOverflow: {
    overflow: "hidden",
    padding: 10,
    boxShadow: '4px 8px 6px -6px #222',
  },
  btn: {
    padding: theme.spacing(2),
    letterSpacing: 2.5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    textAlign: "right",
    display: "block",
    fontSize: 12,
    fontStyle: "italic",
    fontFamily: "Carter One",
  },
}));

const VendorDashboard = ({ vendor, weather, error }) => {
  const classes = useStyles();

  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const timer = React.useRef();

  const welcome = localStorage.getItem("welcome");

  const currentDateCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    const currentDate = moment(new Date(), "LL").format("dddd, MMMM Do, YYYY");
    setDate(currentDate);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();
    currentDateCallback();
    return () => abortController.abort();
  }, [currentDateCallback]);

  const showTimeCallback = React.useCallback(() => {
    setTime(moment().format("LTS"));
    timer.current = setTimeout(showTimeCallback, 1000);
  }, []);

  React.useEffect(() => {
    showTimeCallback();
    return () => clearTimeout(timer.current);
  }, [showTimeCallback]);

  // let iconUrl = getWeatherIcon(
  //   weather && weather.weather && weather.weather[0].icon
  // );

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div className={classes.root}>
      {error && error.error && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={error && error.error ? true : false}
          severity="error"
          message={(error && error.error) || feed.message}
          horizontal="center"
          vertical="bottom"
        />
      )}
      {(!welcome && vendor) || loading ? <WelcomeFeedback /> : null}
      <Typography gutterBottom variant="h4" className={classes.helloText}>
        Hello, {vendor && vendor.companyName}
      </Typography>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="overline" className={classes.analytics}>
            Dashboard
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            style={{ display: "flex", width: 318 }}
            component="div"
            elevation={1}
            variant="outlined"
            square
          >
            <span className={classes.date}>{date}</span>
            <Divider orientation="vertical" flexItem />

            <span className={classes.time}>{time}</span>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.gridContainer2}
        justify="space-between"
        // alignItems="stretch"
        alignItems="flex-start"
        spacing={2}
        // component={Paper}
      >
        <Grid item xs={12} md={12}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} md={6} sm={6}>
              <div>
                <CardContent className={[classes.cardContentOverflow, "card employee-welcome-card"]}>
                  <div className="card-body">
                      <div className="welcome-info">
                          <div className="welcome-content">
                              <h5>Welcome back, {vendor && vendor.companyName}</h5>
                          </div>
                          <div className="welcome-img">
                              <img src={vendor && vendor.photo ? vendor.photo : avatar} className="img-fluid" alt="User" />
                          </div>
                      </div>
                      <div className="welcome-btn">
                          <Button variant="contained" 
                            // onClick={() => history.push("/account")} 
                            className={classes.btn}
                            >View Profile</Button>
                      </div>
                  </div>
                </CardContent>
              </div>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Card>
                <CardContent className={[classes.cardContentOverflow]}>
                  <div className="logobox">
                      <img src={russelsmithgrp} className="" alt="logo" width={40} height={40} />
                  </div>
                  <div className="namebox">
                      {/* <h4>{(user && user.organization) ? `${user && user.organization?.name}` : 'loading...'}</h4> */}
                  </div>
                  <div className="detailbox">
                      <p><span className="title">RusselSmith Nigeria Limited</span> 
                          <span className="det"></span>
                      </p>
                      <p><span className="det">18 Adeola Hopewell Street, Victoria Island, Lagos</span></p>
                  </div>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.vendors.vendor,
  error: state.vendors.authError,
});

export default connect(mapStateToProps, null)(VendorDashboard);
