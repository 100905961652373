import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  makeStyles,
  Checkbox,
  darken,
  fade,
  CircularProgress,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { connect, useDispatch } from "react-redux";
import { loginUser, getProfile } from "../../actions/userActions";
import Feedback from "../../Feedback";
import Logo from "../Logo";
import bgImage from '../../assets/bg_image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#303030",
    background: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    color: "#fefefe",
    overflow: "hidden",
  },
  centered: {
      textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
      borderRadius:0,
      padding: theme.spacing(6),
      background: darken("#fff", 0.05),
      width: 550,
      minHeight: "100vh",
      marginRight:"auto",
      marginLeft:"auto",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
          maxWidth: "50%",
          width: "100%",
          padding: theme.spacing(4),
      },
      [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
          padding: theme.spacing(4),
      },
  },
  form: {
      // width: "100%"
  },
  textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
  },
  btn: {
      margin: "30px 0",
      padding: theme.spacing(3),
      backgroundColor: darken("#0064f8", 0.15),
      height:45,
      borderRadius: 5,
      color: "#fff",
      "&:hover": {
          background: darken("#0064f8", 0.35),
      },
  },
  loginText: {
      fontWeight: "300",
      fontSize: 15,
      margin: 10,
  },
  link: {
      fontSize: 10,
      fontWeight: 400,
      color: theme.palette.type === "light" ? theme.palette.primary.main : "#fff",
      textTransform: "capitalize",
  },
  logoAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: "auto",
    marginBottom: 20,
    boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",

    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: 20,
    },
  },
  logoText: {
    fontWeight: 900,
    // fontFamily: "Rubik",
    fontFamily: "Carter One",
    letterSpacing: 1.5,
    fontSize: 26,
    color:
      theme.palette.type === "light"
        ? fade(theme.palette.common.black, 0.75)
        : "#fff",
    textDecoration: "none",
    display: "inline-block",
  },
}));

const LoginComponent = ({ loginUser, error }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isPassword, showIsPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const [state, setState] = useState({
    email: "",
    password: "",
    error: false,
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleRemember = (e) => {
    setRemember(e.target.checked);
  };

  const showVisibility = () => {
    showIsPassword(!isPassword);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    const abortController = new AbortController();
    const token = localStorage.getItem("token:key");
    if (token) {
      history.push("/dashboard");
    }
    return () => {
      abortController.abort();
    };
  }, [history]);

  // console.log(error);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = state;

    const data = {
      email,
      password,
    };

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    if (remember) {
      localStorage.setItem("rememberme", remember);
    }

    loginUser(data)
      .then((doc) => {
        if (doc.token) {
          setState({
            email: "",
            password: "",
            loading: false,
          });

          setFeed({
            open: !feed.open,
            loading: false,
            // message: doc.error,
            message: "Logged In Successfully",
            success: true,
          });

          // history.push("/");
          // dispatch(getProfile());
          localStorage.setItem("token:key", doc.token);

          if (doc.data.companyName) {
            setTimeout(() => {
              history.push("/dashboard");
              window.location.reload();
            }, 3200);
          } else {
            setTimeout(() => {
              history.push("/dashboard");
              window.location.reload();
            }, 3000);
          }
        } else {
          setState({
            email: "",
            password: "",
          });

          setFeed({
            loading: false,
            open: !feed.open,
            message: "You Have Just One More Step To Go.",
            success: true,
          });
          setRemember(false);

          setTimeout(() => {
            history.push(`${doc.data.link}`);
          }, 3000);

          // setTimeout(() => {
          //   history.push(`/security/twofactor/${doc.data._id}`);
          // }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setState({
          email: "",
          password: "",
          loading: false,
        });
        setFeed({
          open: !feed.open,
          loading: false,
          success: false,
          message: error && error.error,
        });
        history.push(`/`);
      })
      .finally(() => {
        dispatch(getProfile());
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div className={classes.root}>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      )}

      <Card className={classes.card}>
        <div className={classes.centered}>
          <Logo path="/" size={26} />
        </div>

        <Typography
          variant="body1"
          align="center"
          gutterBottom
          className={classes.loginText}
        >
          Please enter your details to login
        </Typography>
        <form className={clsx(classes.form)} onSubmit={handleSubmit}>
          <TextField
            label="Email"
            id="email"
            name="email"
            type="email"
            value={state.email}
            className={classes.textField}
            onChange={handleChange}
            required
            fullWidth
            variant="outlined"
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            value={state.password}
            onChange={handleChange}
            variant="outlined"
            type={isPassword ? "text" : "password"}
            color="primary"
            fullWidth
            className={classes.textField}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                name="remember"
                onChange={handleRemember}
              />
            }
            label="Remember Me"
          />

          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            className={classes.btn}
            disabled={!state.email || !state.password || state.loading}
            endIcon={
              state.loading ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <ExitToAppIcon />
              )
            }
          >
            {state.loading ? "Loading..." : "Login"}
          </Button>

          <Grid container alignContent="space-between">
            <Grid item md={4} sm={4} xs={12} style={{ marginBottom: 10 }}>
              <Link to="/forgotpass" className={classes.link}>
                Forgot Password?
              </Link>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <Link to="/vendor/register" className={classes.link}>
                New Vendor? Create Account
              </Link>
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <Link to="/vendor/requestlink" className={classes.link}>
                Vendor? Request for new link
              </Link>
            </Grid>

          </Grid>
        </form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.user.error,
});

export default connect(mapStateToProps, { loginUser })(LoginComponent);
